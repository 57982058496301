import React from 'react';
import { render } from 'react-dom';
import Chart from './Chart';
import Modal from './Modal';
import { getAllSymbols, getSymbolData, formatNumber } from "./utils";
import { Table } from './Table';
import styled from 'styled-components'

class ChartComponent extends React.Component {
	render() {
		return (
			<div>
				<h2 style={{ "text-align": "center" }}>{this.props.symbol}</h2>
				<Chart type="hybrid" symbol={this.props.symbol} data={this.props.data} />
			</div>
		)
	}
}

const columns = [
	{
		Header: "Symbol",
		accessor: "symbol",
	},
	{
		Header: "Direction",
		id: "output.tendency.direction",
		accessor: "output.tendency.direction",
	},
	{
		Header: "Buyability",
		id: "buyability",
		accessor: d => (d.output.tendency.confidence * safeNumber(d.output.rrr.value) * Math.abs(d.quote.close - d.output.rrr.sell_profit_price) / d.quote.close * 100).toFixed(2) + "%",
		sortType: (a, b, columnId, desc) => {
			const va = a.original.output.tendency.confidence * safeNumber(a.original.output.rrr.value) * Math.abs(a.original.quote.close - a.original.output.rrr.sell_profit_price) / a.original.quote.close;
			const vb = b.original.output.tendency.confidence * safeNumber(b.original.output.rrr.value) * Math.abs(b.original.quote.close - b.original.output.rrr.sell_profit_price) / b.original.quote.close;

			return va - vb;
		}
	},
	{
		Header: "Confidence",
		id: "output.tendency.confidence",
		accessor: d => safeNumber(d.output.tendency.confidence).toFixed(2),
		sortType: (a, b, columnId, desc) => {
			return safeNumber(a.original.output.tendency.confidence) - safeNumber(b.original.output.tendency.confidence);
		}
	},
	{
		Header: "RRR",
		id: "output.rrr.value",
		accessor: d => safeNumber(d.output.rrr.value).toFixed(2),
		sortType: (a, b, columnId, desc) => {
			return safeNumber(a.original.explanation.rrr.value) - safeNumber(b.original.explanation.rrr.value);
		}
	},
	{
		Header: "Stop Loss",
		id: "output.rrr.stop_loss_price",
		accessor: d => safeNumber(d.output.rrr.stop_loss_price).toFixed(2),
		sortType: (a, b, columnId, desc) => {
			return safeNumber(a.original.explanation.rrr.stop_loss_price) - safeNumber(b.original.explanation.rrr.stop_loss_price);
		}
	},
	{
		Header: "Sell Price",
		id: "output.rrr.sell_profit_price",
		accessor: d => safeNumber(d.output.rrr.sell_profit_price).toFixed(2),
		sortType: (a, b, columnId, desc) => {
			return safeNumber(a.original.explanation.rrr.stop_profit_price) - safeNumber(b.original.explanation.rrr.stop_profit_price);
		}
	},
	{
		Header: "Potential Profit",
		id: "potential_profit",
		accessor: d => (Math.abs(d.quote.close - d.output.rrr.sell_profit_price) / d.quote.close * 100).toFixed(2) + "%",
		sortType: (a, b, columnId, desc) => {
			const va = Math.abs(a.original.quote.close - a.original.output.rrr.sell_profit_price) / a.original.quote.close;
			const vb = Math.abs(b.original.quote.close - b.original.output.rrr.sell_profit_price) / b.original.quote.close;

			return va - vb;
		}
	},
	{
		Header: "Latest data",
		id: "quote.timestamp",
		accessor: d => new Date(d.quote.timestamp * 1000).toLocaleString(),
		sortType: (a, b, columnId, desc) => {
			return a.original.quote.timestamp - b.original.quote.timestamp;
		}
	},
];

function safeNumber(num) {
	if (num == null || num == undefined || isNaN(num)) {
		return 0;
	}

	return num;
}
class Stocks extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			symbols: [],
			charted: {
				symbol: null,
				data: null,
			},
			sortColumns: [
				{
					id: columns[2].id,
					desc: true
				},
			]
		};
	}

	componentDidMount() {
		this.loadSymbols();
	}

	loadSymbols() {
		getAllSymbols().then(symbols => this.setState(state => ({ ...state, symbols: Object.entries(symbols).map(([k, v]) => ({ ...v, symbol: k })) })));
	}

	loadSymbol(symbol) {
		getSymbolData(symbol).then(data => {
			this.setState(state => ({
				...state,
				charted: {
					symbol: symbol,
					data: data,
				}
			})
			);
		});
	}

	toggleModal() {
		this.setState(state => ({ ...state, charted: { symbol: null, data: null } }))
	}

	render() {
		//const symbols = this.state.symbols.map(s => <Symbol symbol={s} onClick={() => this.loadSymbol(s.symbol)}/>)
		const symbols = this.state.symbols;
		const charted = this.state.charted;
		const sortColumns = this.state.sortColumns;

		const Styles = styled.div`
padding: 1rem;

table {
  border-spacing: 0;
  border: 1px solid black;

  tr {
	:last-child {
	  td {
		border-bottom: 0;
	  }
	}
	cursor: pointer;
  }

  th,
  td {
	margin: 0;
	padding: 0.5rem;
	border-bottom: 1px solid black;
	border-right: 1px solid black;

	:last-child {
	  border-right: 0;
	}
  }
}
`
		return <div>
			<Styles>
				<Table columns={columns} data={symbols} sortColumns={sortColumns} onRowClick={row => this.loadSymbol(row.original.symbol)} />
			</Styles>
			<Modal show={charted.symbol != null} onClose={() => this.toggleModal()}>
				<ChartComponent symbol={charted.symbol} data={charted.data} />
			</Modal>
		</div>
	}
}

render(<Stocks />, document.getElementById("root"));
