import { tsvParse, csvParse } from  "d3-dsv";
import { timeFormatLocale, timeParse } from "d3-time-format";

function parseData(parse) {
	
	return function(d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;

		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");
export function getData() {
	const promiseMSFT = fetch("https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT.tsv")
		.then(response => response.text())
		.then(data => tsvParse(data, parseData(parseDate)))
	return promiseMSFT;
}

const parseTimestamp = timeParse("%s")

export function getAllSymbols() {
	return fetch("results.json").then(res => {
		return res.json();
	});
}

export function getSymbolData(symbol) {
	return fetch("data/" + symbol + ".data.json")
		.then(res => res.json())
		.then(data => {
			for (var d in data) {
				data[d].quote.date = parseTimestamp(data[d].quote.timestamp)
			}
			return data;
		});
}


export function formatNumber(num) {
	return num.toLocaleString('cz-CZ', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
}
