// Shamelessly taken from https://daveceddia.com/open-modal-in-react/
import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    // The gray background
    const backdropStyle = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      "background-color": 'rgba(0,0,0,0.3)',
      padding: 50
    };

    // The modal "window"
    const modalStyle = {
      "background-color": '#fff',
      "border-radius": 5,
//      "max-width": 500,
      "min-height": "50%",
      margin: '0 auto',
      padding: 30
    };

    return (
      <div style={backdropStyle}>
        <div style={modalStyle}>
            <div style={{"text-align": "right"}}>
                <button style={{cursor: "pointer"}} onClick={this.props.onClose}>x</button>
            </div>
            {this.props.children}
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default Modal;

